(function () {
    'use strict';

    angular
        .module('app')
        .service('auth', auth);

    auth.$inject = ['$q', '$timeout', '$window', 'jwtHelper', '$state', '$http', 'ressursnettverkConfig'];


    function auth($q, $timeout, $window, jwtHelper, $state, $http, ressursnettverkConfig) {
        var apiBaseUrl = ressursnettverkConfig.apiBaseUrl;

        return {
            isAuthenticated: function () {
                var defer = $q.defer();
                if (!$window.localStorage && !$window.localStorage.currentUser) {
                    defer.reject();
                }
               
                var tokenHolder = JSON.parse($window.localStorage.currentUser);;
                var token = tokenHolder["token"];
              
                    var tokenPayloadCached = jwtHelper.decodeToken(token);
                
                    if (_.has(tokenPayloadCached, 'IsAdmin')) {
                        // Fix to convert from string true/false, to boolean true/false
                        const autha = tokenPayloadCached['IsAdmin'].toLowerCase() === 'true';
                        if(autha){
                        defer.resolve(autha);
                        }else{
                            defer.reject();
                        }
                    } else {
                        console.log("redirect to main 3");
                        defer.reject();
                    }
                
                return defer.promise;
            }
        };
    }
})();