(function () {
    'use strict';

    angular
        .module('app')
        .factory('authenticationSvc', service);

    service.$inject = ['$http',  '$window', 'jwtHelper',  'ressursnettverkConfig'];


    function service($http, $window, jwtHelper, ressursnettverkConfig) {
            var ser = {};
            var apiBaseUrl = ressursnettverkConfig.apiBaseUrl;

      function login(mail, password, callback) {

        $http({
          method: 'POST',
          url: apiBaseUrl + 'Auth/authenticate',
          headers: { 'Content-Type': 'application/json' },
          data: { email: mail, password: password }
        }).then(function (response) {
                     //If response code is not 200, we havent't got a new token and should therefore redirect 
                     //to error page
               if (response.status !== 200) {
                 
                 callback(response.data);
                     } else {
                        //var idToken = response.data.token;
                        //  var user = { username: username, token: idToken };
                 $window.localStorage.currentUser = JSON.stringify(response.data);

                         callback("");
                     }
                     // login successful if there's a token in the response
                     
                    },
                function (err) {
                    callback(err.data);
                });
            }

            function logout() {
                // remove user from local storage and clear http auth header
                delete $window.localStorage.currentUser;

        }


        ser.Login = login;
        ser.Logout = logout;
        return ser;
        }
})();