(function () {
    'use strict';

    angular
        .module('app')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$window'];

    // intercept http and decorate it with bearer + token.
    function authInterceptor($rootScope, $q, $window) {
        return {
            request: function (config) {
                config.headers = config.headers || {};
                if ($window.localStorage && $window.localStorage.currentUser && config.url.indexOf('api.bring')=== -1) {
                    var storage = JSON.parse($window.localStorage.currentUser);

                    var token = storage.token;
                    config.headers.Authorization = 'Bearer ' + token;
                }
                return config;
            },
            response: function (response) {
                if (response.status === 401) {
                    // handle the case where the user is not authenticated
                }
                return response || $q.when(response);
            }
        };


    }
})();

